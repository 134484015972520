<template>
  <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
  >
    <template v-slot:imgFile>
      <div v-if="!fileSrc" class="upload-no-img" @click="$refs.uploadfile.click()">+</div>
      <img v-else :src="fileSrc" class="upload-has-img" @click="$refs.uploadfile.click()"/>
      <input type="file" style="display: none;" ref="uploadfile" @change="uploadFile"/>
    </template>
  </dialog-form-list>
</template>

<script>
import {communicationMode} from "@/dict";

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      fileSrc: null,
      fileObj: null,
      formData: {
      },
      config: [
        // lang:名称
        {
          label: this.$t('sensorDeviceManage.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        {
          label: this.$t('sensorDeviceManage.deviceMode'),
          prop: 'deviceMode',
          rules: {
            noNull: true,
          },
        },
        {
          label: this.$t('sensorDeviceManage.measuringUnit'),
          prop: 'measuringUnit',
          rules: {
            noNull: true,
          },
        },
        {
          tag: 'el-select',
          label: this.$t('sensorDeviceManage.communicationMode'),
          prop: 'communicationMode',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.communicationMode(),
          }
        },
        {
          label: this.$t('sensorDeviceManage.maxMeasuringRange'),
          prop: 'maxMeasuringRange',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'number'
          }
        },
        {
          label: this.$t('sensorDeviceManage.minMeasuringRange'),
          prop: 'minMeasuringRange',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'number'
          }
        },
        {
          label: this.$t('sensorDeviceManage.tolerance'),
          prop: 'tolerance',
          rules: {
            noNull: true,
          },
        },
        {
          tag: 'slot-content',
          label: this.$t('sensorDeviceManage.imgFile'),
          prop: 'imgFile',
        },
        {
          label: this.$t('sensorDeviceManage.functionDes'),
          prop: 'functionDes',
          rules: {
          },
          tagProps: {
            type: 'textarea',
            rows: 8,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    uploadFile() {
      const formData = new FormData()
      formData.append('fileFrom', 'SENSOR_DEVICE')
      formData.append('file', this.$refs.uploadfile.files[0])
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
        data: formData,
      }).then(res => {
        console.log(res)
        this.fileId = res.id
        this.fileSrc = res.fileHost + '/' + res.objectName
        this.fileObj = res
      })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.fileId = this.tableRow.imgFile.id
          this.fileSrc = this.tableRow.imgFile.fileHost + '/' + this.tableRow.imgFile.objectName
          this.fileObj = this.tableRow.imgFile
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      if(this.fileObj) {
        formData.imgFile = this.fileObj
      } else {
        formData.imgFile = null
      }
      console.log('formData', formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sensorAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sensorEdit,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
    //添加header
    addHeader() {
      let defaultData = {
        key: null,
        value: null,
      }
      this.formData.headers.push(defaultData)
    },
    // 删除header
    delHeader(index) {
      if(this.formData.headers.length === 1) {
        return
      } else {
        this.formData.headers.splice(index, 1)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/theme.scss';
.header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-input {
    margin-right: 10px;
  }
  i {
    margin-right: 10px;
    cursor: pointer;
    color: $--color-primary;
    &:hover {
      font-weight: bold;
    }
  }
}
.upload-no-img {
  width: 200px;
  height: 200px;
  font-size: 36px;
  line-height: 200px;
  text-align: center;
  border: 1px dashed #cccccc;
  cursor: pointer;
  border-radius: 4px;
}
:hover.upload-no-img {
  border: 1px dashed blue;
  color: blue;
}
.upload-has-img {
  width: 200px;
  height: 200px;
  line-height: 200px;
  cursor: pointer;
  border-radius: 4px;
  object-fit: contain;
  background: black;
}
</style>
